import { createRouter, createWebHistory } from "vue-router"

// no auth views
import Home from "../views/Home.vue"
// import AuthLayout from "@/layout/AuthLayout";
import TopLayout from "@/layout/TopLayout"
import Lost from "../views/404.vue"
// import UserProfile from "../views/UserProfile"
import Login from "@/views/Login.vue"
import LoginLayout from "@/layout/LoginLayout"
import DashboardLayout from "@/layout/DashboardLayout"
import Dashboard from "@/views/Dashboard"

const routes = [
  // {
  //   path: "/login",
  //   component: LoginLayout,
  //   children: [
  //       {
  //         path: "/login",
  //         name: "login",
  //         components: { default: Login },
  //         props: true
  //     }
  //   ]
  // },
  {
    path: "/",
    component: TopLayout,
    children: [
      {
        path: "/",
        name: "home",
        components: { default: Home },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "lost",
        components: { default: Lost },
      },
      {
        path: "/error",
        name: "error",
        components: { default: Lost },
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
})

export default router
