import { createSSRApp } from "vue"
import App from "./App.vue"
import { store } from "./store"
import router from "./router"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore } from "firebase/firestore"
import { initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import "./assets/@fortawesome/fontawesome-free/css/all.min.css"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import moment from "moment"
import i18n from "./lang/lang.js"
import Multiselect from "vue-multiselect"
import "./assets/scss/style.scss"

let firebaseConfig
if (process.env.NODE_ENV === "production") {
    firebaseConfig = require("../prodConfig.json")
} else {
    firebaseConfig = require("../devConfig.json")
}

export const fireApp = initializeApp(firebaseConfig)
export const analytics = getAnalytics(fireApp)
export const auth = getAuth(fireApp)

let site_key= ""
if (process.env.NODE_ENV === "production") {
    site_key = "6Lc6oX0pAAAAAKviZDM8H8QZjJqDD6dhUm99eh--"
} else {
    site_key = "6Lc6oX0pAAAAAKviZDM8H8QZjJqDD6dhUm99eh--"
}

initializeAppCheck(fireApp, {
    provider: new ReCaptchaV3Provider(site_key),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: false,
})

onAuthStateChanged(auth, async (user) => {
    if (user == null) {
        await store.dispatch("signOut")
    }

    // if ((store.state.user.data == null || store.state.user.claims == null) && user !== null) {
    //     await store.dispatch("fetchUser", user);
    //     // eslint-disable-next-line no-prototype-builtins
    // }
})

router.beforeEach(async (to, from, next) => {
    let currentUser = await store.dispatch("isLoggedIn")
    let requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    console.log("requiresAuth", requiresAuth)
    console.log("currentUser", currentUser)
    if (!requiresAuth) {
        next()
        return
    }

    if (requiresAuth && currentUser) {
        next()
    } else {
        next("login")
    }

    console.log("should not be here", requiresAuth)
})

export const firestore = getFirestore()
const appInstance = createSSRApp(App)
// eslint-disable-next-line vue/multi-word-component-names
appInstance.component("multiselect", Multiselect)
appInstance.component("VueDatePicker", VueDatePicker)
appInstance.use(router)
appInstance.use(store)
import GlobalComponents from "./helpers/globalComponents"
moment.locale(store.state.locale)
appInstance.config.globalProperties.$moment = moment
appInstance.use(GlobalComponents)
appInstance.use(moment)
appInstance.config.devotools = true
appInstance.use(i18n)
appInstance.use(VueSweetalert2)
appInstance.mount("#app")