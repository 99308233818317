<template>
  <div :class="[{'main-bg': ['home', 'checkout'].includes($route.name)}]">
<!--    <base-nav-->
<!--        type="primary"-->
<!--        title="Sample Project"-->
<!--        :transparent="true"-->
<!--      class="navbar-top navbar-horizontal"-->
<!--      container-classes="px-4 container"-->
<!--      expand-->
<!--    >-->
<!--    </base-nav>-->
    <!-- Navbar -->
    <div>
      <router-view v-slot="{ Component }">
        <keep-alive include="UserAppointments">
          <component :is="Component" :key="$route.fullPath"></component>
        </keep-alive>
      </router-view>
    </div>
    <div v-if="showCookieMessage" class="cookie-message">
      This website uses cookies to ensure you get the best experience.
      <button class="btn btn-secondary float-end" @click="acceptCookies()">Got it!</button>
    </div>
    <footer class="pt-3 pb-1 bg-primary text-white-50">
      <div class="container">
        <!--     <ul> -->
        <!--       <li><a href="#">Impressum</a></li> -->
        <!--       <li><a href="#">Contact</a></li> -->
        <!--       <li><a href="#">Mainpage</a></li> -->
        <!--     </ul> -->
        <p>&copy; 2024 PineNative LTD. All rights reserved.</p>
      </div>

    </footer>
<!--    <footer class="py-5 bg-gradient-gray">-->
<!--      <div class="container">-->
<!--        <div class="row align-items-start  justify-content-xl-between">-->
<!--&lt;!&ndash;          <div class="col-md-3 text-white"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="col-md-3 text-white"></div>&ndash;&gt;-->
<!--          <div class="col-md-6 text-white text-md-start">-->
<!--            <h3 class="text-white">{{ $t("OUR FLEET") }}</h3>-->
<!--            <a class="link-light text-white-50  text-decoration-none fw-bold" href="/fleet/16-19">6-12 Seater MPVs</a><br>-->
<!--            <a class="link-light text-white-50  text-decoration-none fw-bold" href="/fleet/16-19">16-19 Seater Minibuses</a>-->
<!--            <a class="link-light text-white-50  text-decoration-none fw-bold" href="/fleet/16-19">33-72 Seater Coaches</a><br>-->
<!--          </div>-->
<!--          <div class="col-md-6 text-white text-md-end pt-xs-5">-->
<!--            <h3 class="text-white">{{ $t("CONTACT US") }}</h3>-->
<!--            <div>-->
<!--              <div class="text-white-50 fw-bold"> phone</div>-->
<!--              <div class="text-white-50 fw-bold"> emails@gmail.com</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-xl-12 text-white text-md-start pt-5">-->
<!--            <div class="copyright text-md-right text-xl-left fw-bolder float-md-start">-->
<!--              &copy; {{ year }} Vue3 template-->
<!--</div>-->
<!--            <div class="fw-bold float-end">{{ $t("Sample Project pinenative") }}</div>-->
<!--</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </footer>-->
  </div>
</template>
<script>
import packageData from "../../package.json"
import BaseButton from "@/components/BaseButton"
import BaseNav from "@/components/BaseNav"
import Cookies from "js-cookie"

export default {
  name: "top-layout",
  components: {
    BaseNav,
    BaseButton,
    // VueMultiselect
  },
  data() {
    return {
      locale: "",
      version: null,
      locales: [
        {label: "English", value: "en"},
        {label: "Ελληνικά", value: "el"},
        // {label: 'Russian', value: 'ru'}
      ],
      year: new Date().getFullYear(),
      showMenu: false,
    }
  },
  computed: {
    showCookieMessage() {
      return !this.$store.state.acceptedCookies
    },
  },
  mounted() {
    console.log("Cookies.get()", Cookies.get())
    this.version = packageData.version
    let localeSelected = this.locales.find( l => l.value === this.$store.state.locale)

    if(localeSelected){
      this.locale = localeSelected
    }

  },
  methods: {
    acceptCookies() {
      this.$store.dispatch("acceptCookies")
    },
    setLocale () {
      // this.$store.state.locale = value
      console.log("this.locale", this.locale.value)
      this.$i18n.locale = this.locale.value.toLowerCase()
      this.$store.dispatch("setLocale", this.locale.value.toLowerCase())
    },
  },
}
</script>
<style>
.locale-changer select {
  /*background: transparent;*/
  border: none;
  /*color: white;*/
}
.locale-changer{
  margin-left: auto !important;
  cursor: pointer;
  padding-top: 10px;}

.locale-changer select:focus-visible {
  outline: none;
}
.multiselect.small-select .multiselect__select {
  padding: 4px 6px;
  height: 30px;
  width: 30px;
}

.multiselect.small-select .multiselect__tags {
  max-height: 30px;
  padding: 5px 27px 0 4px;
  min-height: 30px;
  background: unset;
}


@media(min-width: 767px)  {
  .mb-only{
    display: none !important;
  }
}

ul.dropdown-menu.show {
  padding: 0;
}
a.active.router-link-exact-active.nav-link.dropdown-item {
  color: white !important;
}
ul.dropdown-menu.show {
  border-radius: 0px 0 5px 5px;
}
</style>