import { getFunctions, httpsCallable} from "firebase/functions"
import { fireApp } from "@/main"
const FirebaseFunctionsHelper = {
  callFunction(name, data) {
    let functions = getFunctions(fireApp, "europe-west3")
    const addMessage = httpsCallable(functions, name)
    return addMessage(data)
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        return result.data
      })
      .catch((error) => {
        // Getting the Error details.
        return error
      })
  },
    getFunctionsUrl(name) {
        const functions = getFunctions(fireApp)
        return ("https://europe-west3-" + functions.app.options.projectId+".cloudfunctions.net/" + name)
    }
  // callFunctionFromURL(data, name) {
  //   const functions = getFunctions();
  //   const url = "https://europe-west3-"+functions.app.options.projectId+".cloudfunctions.net/" + name
  //
  //   const callableFunction = httpsCallableFromURL(
  //     functions,
  //     // the URL of the function
  //     url
  //   );
  //
  //   callableFunction(data).then((result) => {
  //     // Read result of the Cloud Function.
  //     console.log(result);
  //   });
  // },
}
export default FirebaseFunctionsHelper
