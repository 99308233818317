<template>
  <base-button
      class="text-white"
    :class="
      lState === 'failed'
        ? 'bg-gradient-red border-0'
        : ' border-0'
    "
    :disabled="lState !== '' && lState !== 'failed'"
    @click="handleClicked"
  >
    <template v-if="lState === 'processing'">
      <span class="fas fa-cog fa-spin" aria-hidden="true"></span>
      Loading...
    </template>
    <template v-else-if="lState === 'done'">
      <span :class="btnIcon" aria-hidden="true"></span>
      Done!
    </template>

    <template v-else> <slot></slot> </template>
  </base-button>

  <base-alert v-if="lState === 'failed'" :full-width="true" type="danger" dismissible>
    <!--                    <span class="alert-inner&#45;&#45;icon"><i class="ni ni-hat-3"></i></span>-->
    <span class="alert-inner--text">
      <strong>Process Failed!</strong><br />
      <label class="ps-1">{{ alertMessage }} </label>
    </span>
<!--    <button type="button" class="close" data-dismiss="alert" aria-label="Close">-->
<!--      <span aria-hidden="true"><i class="fa fa-times"></i></span>-->
<!--    </button>-->
  </base-alert>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    action: { type: Function },
    alertMessage: {
      type: String,
      default: "There was an error.",
      description: "Message to show in alert if process fail",
    },
    btnIcon: {
      type: String,
      default: "fas fa-money-check",
      description: "Icon to show when process is finished",
    },
    state: {
      type: String,
      default: "button",
      description: "State of submission",
    },
  },
  emits: ["clicked", "action"],
  data() {
    return {
      lState: this.state,
    }
  },
  watch: {
    // whenever question changes, this function will run
    async state(newState) {
      this.lState = newState
      if (newState === "done") {
        await this.sleep(2)
        // eslint-disable-next-line vue/no-mutating-props
        this.lState = ""
      }
    },
  },
  methods: {
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time * 1000))
    },
    handleClicked(evt) {
      this.$emit("clicked", evt)
    },
    created() {
      this.$emit("action")
    },
  },
}
</script>

<style scoped></style>
