<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns" :columns="columns">
          <th v-for="column in columns" :key="column.label" :class="{ 'sortable': column.sorting, 'sort-active': sortBy === column.key}" @click="column.sorting ? sortByColumn(column) : empty() ">
            {{ $t(column.label) }}
            <i v-if="sortBy === column.key" :class="{ 'fa fa-sort-alpha-desc': sortDirection === 'desc', 'fa fa-sort-alpha-asc': sortDirection === 'asc' }"></i>
            <i v-else :class="{ 'fa fa-sort': column.sorting }"></i>
          </th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr>
      <th v-if="data.length === 0 && loading === false" :colspan="100" class="text-center">No results found.</th>
    </tr>
      <tr v-for="(item, index) in data" :key="index">
        <slot :item="item">
          <td v-for="column in columns" :key="column">
            <template v-if="hasValue(item, column)">
              {{ itemValue(item, column) }}
            </template>
          </td>
        </slot>
      </tr>
    </tbody>
    <tfoot>
        <slot name="footer">
          <tr class="text-center">
            <td colspan="100%">

              <template v-if="paginated && !listFull && !(data.length === 0 && loading === false)">
                <base-button v-if="!loading" :outline="true" size="sm" type="primary" @click="loadMore">{{ $t("Load more") }}</base-button>
                <span v-else class="fas fa-cog fa-spin" aria-hidden="true"></span>
              </template>
            </td>
          </tr>
        </slot>
    </tfoot>
  </table>
</template>
<script>
import {getAnalytics, logEvent} from "firebase/analytics"
export default {
  name: "base-table",
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    pagination: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false,
    },
    listFull: {
      type: Boolean,
      default: false,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    fetchMore: {
      type: Function
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    sortBy: {
      type: String,
      description: "sorting field",
    },
    sortDirection: {
      type: String,
      description: "sort table direction asc or desc",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
  },
  emits: ["fetchMore", "update:loading"],
  data() {
    return {
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`
    },
    colsWithValue() {
      return (row) => {
        return this.columns.filter((column) => this.hasValue(row, column))
      }
    },
  },
  methods: {
    empty(){},
    loadMore(){
      const analytics = getAnalytics()
      logEvent(analytics, "click", {
        item_id: "load_more",
        page_title: this.$route.name
      })

      this.$emit("update:loading", true)
      this.$emit("fetchMore")
    },
    sortByColumn(column){
      this.$emit("update:loading", true)
      this.$emit("fetchMore", {field: column.key, order: this.sortDirection === "asc" ? "desc" : "asc"}, true)
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined"
    },
    itemValue(item, column) {
      return item[column.toLowerCase()]
    },
  },
}
</script>
<style>
.table .thead-light th.sortable{
  cursor: pointer;
}
th.sortable.sort-active {
  background: #ebeced !important;
}
</style>
