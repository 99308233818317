<template>
  <div class="home">
    <div class="bg-image">
      <div class="container">
<!--        <h1>PineNative</h1>-->
        <span class="mb-3 d-block">
          <img width="250" src="@/assets/images/pine_logo.svg">
        </span>
        <h2>Welcome to a world of limitless possibilities. </h2><br>
        <h3>Your premier destination for cutting-edge web and mobile application solutions!</h3>
        <!--        <a class="btn btn-primary animate pt-3" href="#contact-form">Contact us</a>-->
        <br>
        <a href="#contact-form" class="animated-button mt-4">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Contact us
        </a>
      </div>
    </div>
    <section class="">
      <div class="container">
        <div class="row">
          <div class="col-12 details text-center">
            <h2>What we do</h2>
            <p>
              Whether you're seeking to elevate your online presence, streamline your business operations, or
              revolutionize user engagement, our team of experts is here to turn your vision into reality.
            </p><p>With a
              relentless commitment to quality and customer satisfaction, we strive to exceed expectations and deliver
              solutions that not only meet your needs but surpass them.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section--primary row justify-content-md-center">
      <div class="row justify-content-md-center m-0">
      <div class="col-md-10 ">
        <h2 class="text-center">Key features</h2>
        <div class="row">
          <div class="col-4 features">
            <div style="display:inline;">
              <span class="badge badge-custom">1</span>
              <h5 class="text-start">Comprehensive Development Services</h5>
            </div>

            <p class="text-start">
              Offering end-to-end solutions for web and mobile application development, including ideation, design,
              development, testing, deployment, and maintenance.
            </p>
          </div>
          <div class="col-4 features">
            <div style="display:inline;">
              <span class="badge badge-custom">2</span>
              <h5 class="text-start">Multi-Platform Expertise</h5>
            </div>
            <p class="text-start">
              Specializing in developing applications for various platforms including iOS, Android, web, and
              cross-platform frameworks, ensuring a wide reach and consistent user experience across devices.
            </p>
          </div>
          <div class="col-4 features">
            <div style="display:inline;">
              <span class="badge badge-custom">3</span>
              <h5 class="text-start">Customization and Flexibility</h5>
            </div>
            <p class="text-start">
              Providing tailored solutions to meet the specific needs and requirements of clients, with the flexibility
              to accommodate unique business objectives and workflows.
            </p>
          </div>

          <div class="col-4 features">
            <div style="display:inline;">
              <span class="badge badge-custom">4</span>
              <h5 class="text-start">Integration Capabilities</h5>
            </div>
            <p class="text-start">
              Offering integration services to seamlessly connect applications with third-party services, APIs, and
              existing systems, enhancing functionality and data exchange capabilities.
            </p>
          </div>

          <div class="col-4 features">
            <div style="display:inline;">
              <span class="badge badge-custom">5</span>
              <h5 class="text-start">
                Scalability and Performance Optimization
              </h5>
            </div>
            <p class="text-start">
              Building applications that are scalable, robust, and optimized for performance to accommodate growing user
              bases and evolving business needs.
            </p>
          </div>

          <div class="col-4 features">
            <div style="display:inline;">
              <span class="badge badge-custom">6</span>
              <h5 class="text-start">
                Continuous Support and Maintenance
              </h5>
            </div>
            <p class="text-start">
              Providing ongoing support, maintenance, and updates post-launch to address issues, implement enhancements,
              and ensure the long-term success of applications.
            </p>
          </div>
        </div>
      </div>
      </div>
    </section>

    <contact-us></contact-us>

    <section class="section--primary--alt">
      <div class="container">
        <!--     <h3>Take Product with you everywhere you go.</h3> -->
        <p>
          By embodying these features, a web and mobile application solution company can deliver high-quality,
          innovative solutions that address the unique needs of clients across industries and drive digital
          transformation and business growth.
        </p>
      </div>
    </section>

    <!--
    <section class="text--center">
      <div class="container">
        <h3>Contact us</h3>
        <a href="#" class="btn">Order yours now!</a>
      </div>
    </section> -->
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import {helpers, required} from "@vuelidate/validators"
import ContactUs from "@/views/ContactUs"

export default {
  name: "home-iew",
  components: {ContactUs},
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      model: {
        departure: {
          required: helpers.withMessage(this.$t("Please select departure"), required)
        },
        destination: {
          required: helpers.withMessage(this.$t("Please select destination"), required)
        },
        depDatetime: {
          required: helpers.withMessage(this.$t("You need to select a departure date and time."), required)

        },
      },
    }
  },
  data() {
    return {
      availableRoutes: [],
      model: {
        persons: 2,
        departure: null,
        destination: null,
        returnDatetime: null,
        depDatetime: null
      }
    }
  },
  computed: {
  },
  async mounted() {
    // if (this.$store.state.routes) {
    //   this.availableRoutes = this.$store.state.routes
    // } else {
    // }
  },
  methods: {

  },
}
</script>
<style scoped lang="scss">
$color-red: #cc615f;
$color-grey: #1c262b;
$color-grey--light: #29363e;

$color-orange: #e6ac5e;
$color-blue: #007ece;
$color-green: #8fca53;
$color-purple: #68647c;
$color-gold: #efb05a;

$color-primary: #1C2A27;

$bp-s: 43.75em; //700px;
$bp-xs: 34.375em; //550px;

@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css');

@import url(//fonts.googleapis.com/css?family=Open+Sans:400,700,800,300);


* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 100%;
  line-height: 1.45;
  color: #141414;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

.btn {
  display: inline-block;
  margin: 1rem 0;
  color: white;
  font-weight: 500;
  font-size: 1.3rem;
  background: $color-primary;
  letter-spacing: .02em;
  border: none;
  border-radius: 5px;
  padding: .8rem 1rem .9rem;
  text-shadow: 0 1px rgba(black, .3);
  box-shadow: 0 0 2px rgba(black, .2);

  @media (max-width: $bp-s) {
    padding: .5rem .7rem .6rem;
    font-size: 1rem;
  }

  &:hover {
    background: lighten($color-primary, 5%);
    color: #fff;
  }

  &:focus,
  &:active,
  &:focus:active {
    background: darken($color-primary, 5%);
    border-color: darken($color-primary, 5%);
    box-shadow: 0 2px 5px 0 rgba(black, .5) inset;
  }
}

.container {
  margin: 0 auto;
  width: 90%;
  max-width: 900px;
}


header {
  color: white;
  background: $color-primary;
  padding: 10rem 0;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media (max-width: $bp-s) {
    padding: 2rem 0;
  }

  h1 {
    font-size: 3rem;
    margin: 0 0 1rem;

    @media (max-width: $bp-s) {
      font-size: 2rem;
    }
  }

  h2 {
    font-weight: 300;
    font-size: 1.5rem;
    margin: 0 0 1rem;

    @media (max-width: $bp-s) {
      font-size: 1.5rem;
    }
  }
}

section {
  background: #fff;
  color: $color-grey;
  padding: 3.5rem 0;

  @media (max-width: $bp-s) {
    padding: 2rem 0;
  }

  &.section--primary {
    background: $color-primary;
    color: #fff;
  }

  &.section--primary--alt {
    background: desaturate(lighten($color-primary, 15%), 10%);
    color: #fff;
  }

  &.section--primary--light {
    background: rgba($color-primary, .1); //desaturate(lighten(mix($color-primary,white),10%),10%);
  }

  &.section--grey {
    background: $color-grey;
    color: #fff;
  }

  &.section--grey--light {
    background: $color-grey--light;
    color: #fff;
  }

  h3 {
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
    margin: 0 0 1rem;

    @media (max-width: $bp-s) {
      font-size: 1.5rem;
    }
  }

  li {
    font-size: 1.2rem;
    font-weight: 300;
  }

  p {
    font-size: 1.2rem;
    font-weight: 300;
  }
}

.col {
  margin: 0 1.5%;
  display: inline-block;
  vertical-align: top;
}

.col-7 {
  @extend .col;
  width: 64%;

  @media (max-width: $bp-s) {
    width: 100%;
    margin: 0;
  }
}

.col-3 {
  @extend .col;
  width: 29%;

  @media (max-width: $bp-s) {
    width: 100%;
    margin: 0;
  }
}

.col-5 {
  @extend .col;
  width: 30%;

  @media (max-width: $bp-xs) {
    width: 60%;
    margin: 0;
  }
}

.details {
  text-align: left;

  h3 {
    font-size: 2rem;
    text-align: left;
  }
}

.details-img--ball {
  @media (max-width: $bp-s) {
    height: 200px;
    width: auto;
    margin: 0 auto;
  }
}

.features {
  text-align: center;
  padding: 1rem;

  &:hover {
    background: rgba(white, .1);
  }

  @media (max-width: $bp-s) {
    width: 100%;
    margin: 0;
    text-align: left;
    border-bottom: 1px solid rgba(white, .2);

    &:last-child {
      border: none;
    }
  }

  i {
    font-size: 4rem;
    margin: 0 0 2rem 0;

    @media (max-width: $bp-s) {
      font-size: 1.5rem;
      width: 2rem;
      text-align: center;
      margin: 0 0 1rem 0;
      float: left;
    }
  }

  p {
    margin: 0 0 1rem 0;
    font-size: 1rem;

    @media (max-width: $bp-s) {
      margin-left: 3rem;
    }
  }
}

blockquote {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: center;

  &:before {
    display: inline-block;
    color: $color-primary;
    font-size: 2rem;
    content: '\201C';
  }

  p {
    margin: 0;
    display: inline;
    font-size: 1.5rem;

    @media (max-width: $bp-s) {
      font-size: 1.2rem;
    }
  }

  cite {
    font-size: 1rem;
    display: block;
    margin: .5rem 0 0 1.2rem;
    text-style: italic;

    @media (max-width: $bp-s) {
      font-size: .8rem;
    }

    &:before {
      content: '–';
    }
  }
}

footer {
  background: #2d2b2a;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
  font-size: .8rem;
  color: rgba(white, .4);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      a {
        display: block;
        padding: .4rem .7rem;
        font-size: .9rem;
        text-decoration: none;
        color: rgba(white, .7);

        &:hover {
          color: white;
        }
      }
    }
  }
}

.text--center {
  text-align: center;
}

.text--left {
  text-align: left;
}

.badge-custom {
  background: white;
  //border-radius: 23px;
  color: $color-primary;
  font-size: 26px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>

