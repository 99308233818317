// /**
//  *
//  * @param departDate {Date}
//  * @param returnDate {Date?}
//  * @param singleTripCost {Number}
//  * @return number
//  */
// export function calculateTripCost(departDate, returnDate, singleTripCost) {
//   const format = "HH:mm"
//   console.log("departDate?=?=", departDate)
//
//   let tripCost = singleTripCost
//   let departDateMoment = this.$moment(departDate)
//   let returnDateMoment = returnDate ? this.$moment(returnDate) : null
//
//   const afterTime = this.$moment("19:00", format),
//       beforeTime = this.$moment("07:00", format)
//   // console.log("time now????", this.$moment().toDate())
//   console.log("departTime ==", departDateMoment.format("dd MM yyyy HH:mm:ss"))
//   // console.log("beforeTime", beforeTime.format('dd MM yyyy HH:mm:ss'))
//   // // console.log("afterTime", afterTime.format('dd MM yyyy HH:mm'))
//   // console.log("departTime isBetween?", departTime > beforeTime)
//
//   if(departDateMoment.hour() >=  afterTime.hour() || departDateMoment.hour() < beforeTime.hour()){
//     tripCost = tripCost + getPercentage(singleTripCost, 15)
//   }
//
//   if(returnDateMoment) {
//     if(returnDateMoment.hour() >=  afterTime.hour() || returnDateMoment.hour() < beforeTime.hour()){
//       tripCost += getPercentage(singleTripCost, 15)
//     }
//
//     tripCost += singleTripCost
//
//   }
//
//
//   // console.log("returnTime", returnTime)
//
//   return Math.round(tripCost)
//
//
//
// }

exports.getPercentage = (num, per) =>
{
  return (num/100)*per
}

// /**
//  *
//  * @param date {FirebaseFirestore.Timestamp| Date | String }
//  * @returns {moment.Moment} for checking use moment.isValid()
//  */
// exports.getMomentDate = (date = new Date()) => {
//   if (date instanceof admin.firestore.Timestamp) {
//     return this.$moment(date.toDate())
//   } else if (date instanceof Date) {
//     return this.$moment(date)
//   } else
//     return this.$moment(date) //`returns invalid Moment object`
// }

exports.getStatusClass = (val) => {
  // type (primary|info|danger|default|warning|success)
  switch (val) {
    case "VERIFIED":
    case "REFUNDED":
    case "PAID":
      return "bg-gradient-green"
    case "PENDING":
    case "PENDING_REFUND":
      return "bg-warning"
    case "CLAIMED":
      return "bg-blue"
    case "FINISHED":
      return "bg-black"
    default:
      return "bg-danger"
  }
}

exports.toCents = (val) => {
  return parseFloat(val).toFixed(2) * 100
}

exports.countDecimals = (val) => {
  if(Math.floor(this.valueOf()) === this.valueOf()) return 0
  return this.toString().split(".")[1].length || 0
}

exports.isoCountryCodeToFlagEmoji = (country) => {
  return String.fromCodePoint(...[...country.toUpperCase()].map(c => c.charCodeAt() + 0x1F1A5))
}

exports.isWindows = navigator.platform.indexOf("Win") > -1