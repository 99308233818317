<template>
  <section id="contact-form" class="section section-lg pt-3 section-contact-us">
    <div class="container">
      <div class="row justify-content-center mt-5">
        <div class="col-lg-8">
          <div class="card shadow bg-gradient-secondary">
            <div class="card-body p-lg-5">
              <h4 class="mb-1">🚀{{ $t(" Let's Discuss Your Project!") }}</h4>
              <p class="mt-2">{{ $t("Ready to elevate your digital presence? ") }} <br>
                {{ $t(" Have a project in mind? ") }} <br>
                {{ $t(" Contact us today, and let's turn your vision into reality!") }} <br>
                {{ $t("Reach out for a free consultation and let the collaboration begin.") }}</p>
              <div class="form-group mt-5 input-group input-group-alternative"
                   :class="[{ 'has-danger': v$.model.name.$error }]">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-user"></i></span>
                </div>
                <input v-model="model.name" aria-describedby="addon-right addon-left"
                       :placeholder="$t('Name/Company name')" class="form-control">
                <div v-if="v$.model.name.$invalid" class="text-danger invalid-feedback text-start  pb-2"
                     :class="{'d-block' : v$.model.name.$errors.length > 0}"
                    >
                  {{
                    v$.model.name.$errors.map(err => {
                      return err.$message
                    })[0]
                  }}
                </div>
              </div>

              <div class="form-group input-group input-group-alternative">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-phone"></i>
                    </span>
                </div>
                <base-input
                    v-model="model.phoneNumber"
                    aria-describedby="addon-right addon-left"
                    type="number"
                    :form-classes="[{ 'has-danger': v$.model.phoneNumber.$error, 'form-control phone-input m-0 p-0': true }]"
                    style="display: flex;border-radius: 0;"
                    :placeholder="$t('Contact number')"
                >
                  <template #addonLeft>
                    <select v-model="model.phoneNumberCode" class="form-control form-control-alternative"
                            style="padding: 0;height: 23px;border: none !important;">
                      <option v-for="country in countryCodes" :key="country.code" :value="country.code">
                        {{ isWindows ? '' : isoCountryCodeToFlagEmoji(country.iso) }} {{ country.iso }} {{
                          country.code
                        }}
                      </option>
                    </select>
                  </template>
                </base-input>
                <div v-if="v$.model.phoneNumber.$invalid"
                     class="text-danger invalid-feedback text-start"
                     :class="{'d-block' : v$.model.phoneNumber.$errors.length > 0}"
                >
                  {{
                    v$.model.phoneNumber.$errors.map(err => {
                      return err.$message
                    })[0]
                  }}
                </div>
              </div>

              <div class="form-group input-group input-group-alternative"
                   :class="[{ 'has-danger': v$.model.email.$error }]">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-envelope"></i>
                    </span>
                </div>
                <input v-model="model.email" aria-describedby="addon-right addon-left"
                       :placeholder="$t('Email address')" class="form-control">
                <div v-if="v$.model.email.$invalid" class="text-danger invalid-feedback text-start"
                     :class="{'d-block' : v$.model.email.$errors.length > 0}"
                >
                  {{
                    v$.model.email.$errors.map(err => {
                      return err.$message
                    })[0]
                  }}
                </div>
              </div>
              <div class="form-group input-group input-group-alternative"
                   :class="[{ 'has-danger': v$.model.email.$error }]">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-book"></i>
                    </span>
                </div>
                <input v-model="model.subject" aria-describedby="addon-right addon-left"
                       :placeholder="$t('Subject')" class="form-control">
                <div v-if="v$.model.subject.$invalid" class="text-danger invalid-feedback text-start"
                     :class="{'d-block' : v$.model.subject.$errors.length > 0}"
                >
                  {{
                    v$.model.email.$errors.map(err => {
                      return err.$message
                    })[0]
                  }}
                </div>
              </div>
              <div class="form-group mb-4"
                   :class="[{ 'has-danger': v$.model.description.$error }]">
                  <textarea v-model="model.description" name="description" rows="4" cols="80"
                            :placeholder="$t('Type a message...')" class="form-control form-control-alternative">
                  </textarea>
                <div v-if="v$.model.description.$invalid" class="text-danger invalid-feedback text-start"
                     :class="{'d-block' : v$.model.description.$errors.length > 0}"
                >
                  {{
                    v$.model.description.$errors.map(err => {
                      return err.$message
                    })[0]
                  }}
                </div>
              </div>
              <submit-button :alert-message="errorMessage"
                             :state="loadingProcess" type="button" class="btn btn-secondary btn-lg text-white"
                             @clicked="submitForm">
                {{ $t("Send message") }}
              </submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import {email, helpers, required} from "@vuelidate/validators"


import SubmitButton from "@/components/SubmitButton"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import {isWindows} from "@/helpers/common"
import {getCountries, getCountryCallingCode, isValidNumber} from "libphonenumber-js"
import BaseInput from "@/components/BaseInput"

const isValidPhone = (param) => helpers.withParams(
    {type: "isValidPhone", value: param}, (value) => {

      if (value == null || value === "") {
        return true
      }
      console.log("param+value", param + value)
      return isValidNumber(param + value)
    })
export default {
  name: "ContactUs",
  components: {BaseInput, SubmitButton},
  setup: () => ({v$: useVuelidate()}),
  data: () => {
    return {
      countryCodes: [],
      isWindows,
      loadingProcess: "",
      errorMessage: "",
      model: {
        name: null,
        email: null,
        subject: null,
        description: "",
        phoneNumber: "",
        phoneNumberCode: "+357",
      }
    }
  },
  validations() {
    return {
      model: {
        name: {
          required
        },
        email: {
          required, email
        },
        subject: {
          required
        },
        description: {
          required
        },
        phoneNumber: {
          required,
          validated: helpers.withMessage(this.$t("Must be valid phone number."), isValidPhone(this.model.phoneNumberCode))
        },
      }
    }
  },
  mounted() {
    let countries = getCountries()

    countries.forEach(country => {
      let code = getCountryCallingCode(country)
      this.countryCodes.push({code: "+" + code, text: country + " +" + code, iso: country})
    })
  },
  methods: {
    isoCountryCodeToFlagEmoji(country) {
      return String.fromCodePoint(...[...country.toUpperCase()].map(c => c.charCodeAt() + 0x1F1A5))
    },
    submitForm() {
      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }
      this.loadingProcess = "processing"

      // sendMail
      FirebaseFunctionsHelper.callFunction("settings-contactForm", {
        name: this.model.name,
        subject: this.model.subject,
        message: this.model.description,
        email: this.model.email,
        phoneNumber: this.model.phoneNumberCode + this.model.phoneNumber
      }).then((data) => {
        if (data === null) {
          this.loadingProcess = "done"
          this.model.name = null
          this.model.email = null
          this.model.subject = null
          this.model.phoneNumber = ""
          this.model.description = ""
          this.v$.$reset()
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: this.$t("Thank you for contacting us!"),
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer)
              toast.addEventListener("mouseleave", this.$swal.resumeTimer)
            }
          })
        } else {
          this.loadingProcess = "failed"
          this.errorMessage = this.$t(data.message)
        }
      }).catch((error) => {
        this.loadingProcess = "failed"
        this.errorMessage = error.message
        console.error(error)
      })
    }
  }
}
</script>

<style>
@media (min-width: 992px) {
  .pb-300 {
    padding-bottom: 300px !important;
  }
}

.section-shaped {
  margin: 180px 0;
  position: relative;
}

@media (min-width: 992px) {
  .mt--300 {
    margin-top: -300px !important;
  }
}

.custom-transition-form {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow 0.15s ease;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.custom-transition-form .multiselect__tags {
  border: none !important;
}

.col-lg-4.box {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.col-lg-4.box:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  /*transition: box-shadow,transform, background 0.3s ease-in-out;*/
  background: #0e799a;
  -webkit-transform: scale(1.15, 1.15);
  transform: scale(1.15, 1.15);
}

.box .icon i {
  font-size: 2.3rem;
}

.form-group.input-group.form-control.phone-input.m-0.p-0 .input-group-prepend {
  border-radius: 0;
}
</style>


