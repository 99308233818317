import { createStore } from "vuex"
import Cookies from "js-cookie"
import VuexPersistence from "vuex-persist"
import { getAuth } from "firebase/auth"
import { fireApp } from "@/main"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  // collectionGroup,
  doc,
  getDoc,
  setDoc,
  writeBatch,
  getFirestore,
  getDocs,
  where,
  deleteDoc,
  query,
  limit,
  addDoc,
  // startAt,
  // startAfter
  // onSnapshot
} from "firebase/firestore"

export const store = createStore({
  plugins: [new VuexPersistence().plugin],
  state: {
    locale: "en",
    showLogin: false,
    acceptedCookies: Cookies.get("acceptedCookies"),
    user: {
      claims: null,
      loggedIn: false,
      data: null,

    },
  },
  mutations: {
    ACCEPTED_COOKIES(state) {
      console.log("ACCEPTED_COOKIESACCEPTED_COOKIESACCEPTED_COOKIES")
      state.acceptedCookies = true
      Cookies.set("acceptedCookies", true, { expires: 365 })
    },
    SET_LOCALE (state, lang) {
      state.locale = lang
    },
    RESET_STATE(state) {
      state.user = {
        claims: {role: null},
            loggedIn: false,
            data: null,
      }
    },
    SET_CLIENT(state, data) {
      state.user.clientData = data
    },
    SET_LOGGED_IN(state, data) {
      state.user.loggedIn = data
    },
    SET_VEHICLES(state, data){
      state.vehicles = data
    },
    SET_USER(state, data) {
      state.user.data = data
    },
    SET_USER_PRIVATE(state, data) {
      state.user.private = data
    },
    SET_USER_CLAIMS(state, data) {
      state.user.claims = data
    },
    SET_ROUTES(state, data) {
      state.routes = data
    },
  },
  getters: {},
  actions: {
    acceptCookies({ commit }) {
      commit("ACCEPTED_COOKIES")
    },
    setLocale ({ commit }, lang) {
      commit("SET_LOCALE", lang)
    },
    async getClaims() {
      return await getAuth(fireApp)
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          return idTokenResult.claims
        })
        .catch((error) => {
          console.log(error)
          return null
        })
    },
    isLoggedIn({ commit }) {
      if (this.state.user.loggedIn){
        return true
      }
      let currUser = getAuth(fireApp).currentUser
      console.log("currUser", currUser)
      commit("SET_LOGGED_IN", currUser != null)
      return currUser != null
    },
    signOut({ commit }) {
      return getAuth(fireApp)
        .signOut()
        .then(() => {
          // console.log("logged out");
          commit("RESET_STATE")
          return true
        })
    },
    async fetchDocs({commit}, path) {
      let fireS = getFirestore(fireApp)
      let collectionRef = collection(fireS, path)
      const docsQuery = query(
          collectionRef,
          limit(100)
      )

      return await getDocs(docsQuery).then((docs) => {
        let vehicles = []
        docs.forEach((doc) => {
          let data = doc.data()
          data["id"] = doc.id
          vehicles.push(data)
        })
        return vehicles
      })
    },
    // eslint-disable-next-line no-unused-vars
    async deleteDocument({commit}, data) {
      let fireS = getFirestore(fireApp)
      await deleteDoc(doc(fireS, data.path, data.id))

    },
  },
  modules: {},
})

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}