<template>
  <nav
      class="navbar"
      :class="[
      { 'navbar-expand-md': expand },
      { 'navbar-transparent': transparent },
      { [`bg-${type}`]: type },
    ]"
  >
    <div :class="containerClasses">
      <slot name="brand">
        <label
            v-if="['home', 'lost', 'login'].includes($route.name)"
            class="h2 mb-0 text-white d-lg-inline-block"
>
<!--          <img src="/logo.png" width="50" alt="Logo"/>-->
          {{ title }}
        </label>
      </slot>
<!--      <navbar-toggle-button-->
<!--          v-if="showToggleButton"-->
<!--          :toggled="toggled"-->
<!--          :target="contentId"-->
<!--          @click.stop="toggled = !toggled"-->
<!--      >-->
<!--        <span class="navbar-toggler-icon"></span>-->
<!--      </navbar-toggle-button>-->

      <div
          :id="contentId"
          class="collapse show float-end"
          :class="{ show: toggled }"
      >
        <slot :close-menu="closeMenu"></slot>
      </div>
      <div v-if="['home','lost'].includes($route.name)">

        <router-link v-if="hasPortalAccess" :to="portalLink" class="btn btn-secondary me-2">
            Portal
        </router-link>
        <router-link v-if="!$store.state.user.loggedIn" to="/login" class="btn btn-secondary">
          Log In
        </router-link>
        <base-button v-else class="text-white" @click="$store.dispatch('signOut')"> Log Out</base-button>
      </div>
    </div>
  </nav>
</template>
<script>
// import NavbarToggleButton from "./NavbarToggleButton"

import BaseButton from "@/components/BaseButton"
export default {
  name: "base-nav",
  components: {
    BaseButton
    // NavbarToggleButton,
  },
  props: {
    type: {
      type: String,
      default: "asas",
      description: "Navbar type (e.g default, primary etc)",
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar",
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
          "Explicit id for the menu. By default it's a generated random number",
    },
    containerClasses: {
      type: [String, Object, Array],
      default: "container-fluid",
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent",
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class",
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toggled: false,
    }
  },
  mounted() {
    console.log(this.$route)
  },
  methods: {
    closeMenu() {
      this.toggled = false
    },
  },
}
</script>
<style></style>
